import React, { lazy } from 'react';
import { Suspense } from 'react';
import { BreadcrumbItem, Spinner } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from '../Footer/Footer';
import ContactUsPage from '../../Views/ContactUsPage/ContactUsPage';
import MvpDevelopment from '../MvpDevelopment/MvpDevelopment';
import MobileDevelopment from '../MobileDevelopment/MobileDevelopment';
import MachineLearningAndAI from '../MachineLearningAndAI/MachineLearningAndAI';
import BpoServices from '../BpoServices/BpoServices';

const Home = lazy(() => import('../../Views/Home/Home'));
const Services = lazy(() => import('../../Views/Services/Services'));
const Career = lazy(() => import('../../Views/Career/Career'));
const AboutUs = lazy(() => import('../../Views/AboutUs/AboutUs'));
const Header = lazy(() => import('../Header/Header'));
const Blockchain = lazy(() => import('../Blockchain/Blockchain'));
const Devops = lazy(() => import('../Devops/Devops'));
const UxDesigns = lazy(() => import('../UxDesigns/UxDesigns'));
const WebDevelopment = lazy(() => import('../WebDevelopment/WebDevelopment'));
// const NavbarLinks = lazy(() => import("../components/Navbar/NavbarLinks"));

function ApplicationRoutes() {
    return (
        <Router basename='/'>
            <Header />

            <Suspense
                style={{
                    backgroundColor: '#9ccb46 0% 0% no-repeat padding-box  !important',
                    color: '#9ccb46 !important'
                }}
                fallback={
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <Spinner animation="grow" color="white" size="xl" />
                    </div>
                }
            >
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/career" element={<Career />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/blockchain" element={<Blockchain />} />
                    <Route path="/contact-us" element={<ContactUsPage />} />
                    <Route path="/mobile-devlopment" element={<MobileDevelopment />} />
                    <Route path="/web-development" element={<WebDevelopment />} />
                    <Route path="/ui-ux-designs" element={<UxDesigns />} />
                    <Route path="/machine-learning-and-AI" element={<MachineLearningAndAI />} />
                    <Route path="/devops" element={<Devops />} />
                    <Route path="/mvp-development" element={<MvpDevelopment />} />
                    <Route path="/bpo-services" element={<BpoServices />} />
                </Routes>
            </Suspense>
            <Footer />
        </Router>
    );
}

export default ApplicationRoutes;
