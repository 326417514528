import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import './ContactUsForm.scss';

const ContactUsForm = () => {
    const loginSchema = Yup.object().shape({
        firstName: Yup.string().trim().required('Required!'),
        email: Yup.string().trim().required('Required!'),
        number: Yup.string().trim().required('Required!')
    });

    const handleSubmit = async (values) => {
        toast.success('Form submitted');
    };
    return (
        <div className="conatct-form">
            <div className="mb-3">
                <h2 className="text-white">Send A Message</h2>
            </div>
            <Formik
                initialValues={{
                    firstName: '',
                    subject: '',
                    email: '',
                    number: '',
                    message: ''
                }}
                validationSchema={loginSchema}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({ errors, touched, values, handleChange, handleSubmit }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className="w-100 m-0">
                            <Col lg={12} className="ps-0 pe-0">
                                <Form.Group className="mb-3" controlId="firstName">
                                    <Form.Control type="Name" placeholder="Name" name="firstName" onChange={handleChange} value={values.firstName} />
                                    <p className="errors"> {errors.firstName && touched.firstName ? <div>{errors.firstName}</div> : null}</p>
                                </Form.Group>
                            </Col>
                            <Col lg={12} className="ps-0 pe-0">
                                <Form.Group className="mb-3" controlId="number">
                                    <Form.Control type="number" placeholder="Phone Number" name="number" onChange={handleChange} value={values.number} />
                                    <p className="errors"> {errors.number && touched.number ? <div>{errors.number}</div> : null}</p>
                                </Form.Group>
                            </Col>

                            <Col lg={12} className="ps-0 pe-0">
                                <Form.Group className="mb-3" controlId="Email">
                                    <Form.Control type="Name" placeholder="Email" name="email" onChange={handleChange} value={values.email} />
                                    <p className="errors"> {errors.email && touched.email ? <div>{errors.email}</div> : null}</p>
                                </Form.Group>
                            </Col>

                            <Col lg={12} className="ps-0 pe-0">
                                <Form.Group className="mb-3" controlId="subject">
                                    <Form.Control type="Name" placeholder="Subject" name="subject" onChange={handleChange} value={values.subject} />
                                    <p className="errors"> {errors.subject && touched.subject ? <div>{errors.subject}</div> : null}</p>
                                </Form.Group>
                            </Col>

                            <Col lg={12} className="ps-0 pe-0">
                                <Form.Control as="textarea" name="message" placeholder="Message" style={{ height: '144px' }} onChange={handleChange} value={values.message} />
                                <p className="errors"> {errors.message && touched.message ? <div>{errors.message}</div> : null}</p>
                            </Col>
                            <Col lg={12} className="ps-0 pe-0 mt-3">
                                <button type="submit" className="send-btn">
                                    Send A Message
                                </button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
            <ToastContainer autoClose={1500} />
        </div>
    );
};

export default ContactUsForm;
