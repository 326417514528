import React from 'react';
import './ContactUsPage.scss';
import ContactUs from '../../Components/ContactUs/ContactUs';

const ContactUsPage = () => {
    return (
        <div className="contact-us-section">
            <div className="for-bg">
                <p>Get in touch</p>
                <h2>Let’s talk</h2>
            </div>
            <ContactUs />
        </div>
    );
};

export default ContactUsPage;
