import React, { useState } from 'react';
import { Tab, Tabs, Container, Row, Col } from 'react-bootstrap';
import './TechnologyStack.scss';
import html5 from '../../assets/images/html5.svg';
import css3 from '../../assets/images/css3-alt.svg';
import javascript from '../../assets/images/js.svg';
import boostrap from '../../assets/images/bootstrap.svg';
import laravel from '../../assets/images/laravel.svg';
import symfony from '../../assets/images/symfony.svg';
import flutter from '../../assets/images/flutter.png';
import php from '../../assets/images/php.svg';
import wordpress from '../../assets/images/wordpress.svg';
import andriod from '../../assets/icons/android.svg';
import ios from '../../assets/icons/app-store-ios.svg';
import codepen from '../../assets/icons/codepen.svg';
import java from '../../assets/icons/java.svg';
import native from '../../assets/icons/react.svg';
import swift from '../../assets/icons/swift.svg';
import bitcoin from '../../assets/icons/blockchain-icons/bitcoin.svg';
import blockchain from '../../assets/icons/blockchain-icons/blockchain.svg';
import ethereum from '../../assets/icons/blockchain-icons/ethereum.svg';
import metamask from '../../assets/icons/blockchain-icons/metamask.png';
import ripple from '../../assets/icons/blockchain-icons/ripple.svg';
import solidity from '../../assets/icons/blockchain-icons/solidity.svg';
import ae from '../../assets/icons/design-icons/ae.svg';
import ai from '../../assets/icons/design-icons/ai.svg';
import ap from '../../assets/icons/design-icons/ap.svg';
import xd from '../../assets/icons/design-icons/xd.svg';
import figma from '../../assets/icons/design-icons/figma.svg';
import innota from '../../assets/images/database-images/ingots.png';
import mongo from '../../assets/images/database-images/mongo.png';
import sqlLite from '../../assets/images/database-images/icons8-mysql-50.png';
import sql from '../../assets/images/database-images/sql.png';
import sqlLit from '../../assets/images/database-images/sql-lite.png';
import postman from '../../assets/images/testing-img/postman.png';
import jira from '../../assets/images/testing-img/jira.png';
import trello from '../../assets/images/testing-img/trello.png';
import selenium from '../../assets/images/testing-img/selenium.png';
import jmeter from '../../assets/images/testing-img/pressure.png';
import jenkins from '../../assets/images/jenkins.png';
import docker from '../../assets/images/docker.png';
import bitbucket from '../../assets/images/bitbucket.png';

const TechnologyStack = () => {
    const [key, setKey] = useState('web');

    const webData = [
        {
            img: html5,
            title: 'Html5'
        },
        {
            img: css3,
            title: 'CSS3'
        },
        {
            img: javascript,
            title: 'JavaScript'
        },
        {
            img: boostrap,
            title: 'Boostrap'
        },
        {
            img: laravel,
            title: 'Laravel'
        },
        {
            img: symfony,
            title: 'Symfony'
        },
        {
            img: php,
            title: 'Php'
        },
        {
            img: wordpress,
            title: 'Wordpress'
        }
    ];

    const mobileData = [
        {
            img: laravel,
            title: 'Laravel'
        },
        {
            img: andriod,
            title: 'Andriod'
        },
        {
            img: ios,
            title: 'Ios'
        },
        {
            img: codepen,
            title: 'Codepen'
        },
        {
            img: java,
            title: 'Java'
        },

        {
            img: native,
            title: 'React Native'
        },
        {
            img: swift,
            title: 'Swift'
        },
        {
            img: flutter,
            title: 'Flutter'
        }
    ];

    const uiUxData = [
        {
            img: ae,
            title: 'Adobe After Effects'
        },
        {
            img: ai,
            title: 'Adobe Illustrator'
        },
        {
            img: ap,
            title: 'Adobe Photshop'
        },
        {
            img: xd,
            title: 'Adobe XD'
        },
        {
            img: figma,
            title: 'Figma'
        }
    ];

    const blockChainData = [
        {
            img: bitcoin,
            title: 'Bitcoin'
        },
        {
            img: blockchain,
            title: 'Blockchain'
        },
        {
            img: ethereum,
            title: 'Ethereum'
        },
        {
            img: metamask,
            title: 'Metamask'
        },
        {
            img: ripple,
            title: 'Ripple'
        },

        {
            img: solidity,
            title: 'Solidity'
        }
    ];

    const dataBaseData = [
        {
            img: innota,
            title: 'Innota'
        },
        {
            img: mongo,
            title: 'MongoDB'
        },
        {
            img: sqlLite,
            title: 'MySQL'
        },
        {
            img: sql,
            title: 'PostgreSQL'
        },
        {
            img: sqlLit,
            title: 'SQLite'
        }
    ];
    const testingeData = [
        {
            img: postman,
            title: 'Postman'
        },
        {
            img: jira,
            title: 'Jira'
        },
        {
            img: trello,
            title: 'Trello'
        },
        {
            img: selenium,
            title: 'Selenium'
        },
        {
            img: jmeter,
            title: 'JMeter'
        }
    ];
    const devopsData = [
        {
            img: docker,
            title: 'Docker'
        },
        {
            img: jira,
            title: 'Jira'
        },
        {
            img: trello,
            title: 'Cloud Provider'
        },
        {
            img: bitbucket,
            title: 'Bitbucket'
        },
        {
            img: jenkins,
            title: 'Jenkins'
        },
        {
            img: jmeter,
            title: 'AWS Ecs'
        }
    ];

    return (
        <div className="tech-stack mt-5">
            <Container className="text-center">
                <h2>Our Technology Stacks </h2>
                <Tabs id="technology-stack-tabs" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 mt-4">
                    <Tab eventKey="web" title="Web">
                        <Row className="w-100 m-0 mt-5">
                            {webData.map((item) => (
                                <Col lg={3} md={3} sm={4} xs={6} className=" mb-3 d-flex justify-content-center align-items-center flex-column">
                                    <div className="img-div mb-3">
                                        <img src={item.img} alt={item.title} width="40px" />
                                    </div>
                                    <h6>{item.title}</h6>
                                </Col>
                            ))}
                        </Row>
                    </Tab>
                    <Tab eventKey="mobile" title="Mobile">
                        <Row className="w-100 m-0 mt-5">
                            {mobileData.map((item) => (
                                <Col lg={3} md={3} sm={4} xs={6} className=" mb-3 d-flex justify-content-center align-items-center flex-column">
                                    <div className="img-div mb-3">
                                        <img src={item.img} alt={item.title} width="40px" />
                                    </div>
                                    <h6>{item.title}</h6>
                                </Col>
                            ))}
                        </Row>
                    </Tab>
                    <Tab eventKey="blockchain" title="Blockchain">
                        <Row className="w-100 m-0 mt-5">
                            {blockChainData.map((item) => (
                                <Col lg={3} md={3} sm={4} xs={6} className=" mb-3 d-flex justify-content-center align-items-center flex-column">
                                    <div className="img-div mb-3">
                                        <img src={item.img} alt={item.title} width="40px" />
                                    </div>
                                    <h6>{item.title}</h6>
                                </Col>
                            ))}
                        </Row>
                    </Tab>
                    <Tab eventKey="ui-ux-design" title="UI/UX Design">
                        <Row className="w-100 m-0 mt-5">
                            {uiUxData.map((item) => (
                                <Col lg={3} md={3} sm={4} xs={6} className=" mb-3 d-flex justify-content-center align-items-center flex-column">
                                    <div className="img-div mb-3">
                                        <img src={item.img} alt={item.title} width="40px" />
                                    </div>
                                    <h6>{item.title}</h6>
                                </Col>
                            ))}
                        </Row>
                    </Tab>
                    <Tab eventKey="database" title="Database">
                        <Row className="w-100 m-0 mt-5">
                            {dataBaseData.map((item) => (
                                <Col lg={3} md={3} sm={4} xs={6} className=" mb-3 d-flex justify-content-center align-items-center flex-column">
                                    <div className="img-div mb-3">
                                        <img src={item.img} alt={item.title} width="40px" />
                                    </div>
                                    <h6>{item.title}</h6>
                                </Col>
                            ))}
                        </Row>
                    </Tab>
                    <Tab eventKey="testing" title="Testing">
                        <Row className="w-100 m-0 mt-5">
                            {testingeData.map((item) => (
                                <Col lg={3} md={3} sm={4} xs={6} className=" mb-3 d-flex justify-content-center align-items-center flex-column">
                                    <div className="img-div mb-3">
                                        <img src={item.img} alt={item.title} width="40px" />
                                    </div>
                                    <h6>{item.title}</h6>
                                </Col>
                            ))}
                        </Row>
                    </Tab>
                    <Tab eventKey="Devops" title="Devops">
                        <Row className="w-100 m-0 mt-5">
                            {devopsData.map((item) => (
                                <Col lg={3} md={3} sm={4} xs={6} className=" mb-3 d-flex justify-content-center align-items-center flex-column">
                                    <div className="img-div mb-3">
                                        <img src={item.img} alt={item.title} width="40px" />
                                    </div>
                                    <h6>{item.title}</h6>
                                </Col>
                            ))}
                        </Row>
                    </Tab>
                </Tabs>
            </Container>
        </div>
    );
};

export default TechnologyStack;
