import React, { useState } from 'react';
import logo from '../../assets/images/white-logo.png';
import { Col, Container, Row } from 'react-bootstrap';
import './Footer.scss';
import phone from '../../assets/images/phone.png';
import facebook from '../../assets/icons/facebook.svg';
import twitter from '../../assets/icons/twitter.svg';
import linkdin from '../../assets/icons/linkdin.svg';
import pintrast from '../../assets/icons/pintrast.svg';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const [activeIcon, setActiveIcon] = useState(1);

    const navigate = useNavigate();

    const socialIcons = [
        {
            id: 1,
            icon: facebook,
            width: '9px'
        },
        {
            id: 2,
            icon: twitter,
            width: '15px'
        },
        {
            id: 3,
            icon: linkdin,
            width: '15px'
        },
        {
            id: 4,
            icon: pintrast,
            width: '15px'
        }
    ];
    return (
        <div className="footer mt-5">
            <Container>
                <Row className="w-100 m-0 align-items-center">
                    <Col className="mt-3">
                        <img src={logo} alt="logo" width="300px" />
                    </Col>
                    <Col className="mt-3 d-flex justify-content-end">
                        <div className=" for-contacts w-100 m-0 align-items-center justify-content-end">
                            <div className="phone">
                                <img src={phone} alt="phone" />
                            </div>

                            <div className="ms-3">
                                <p className="m-0"> Have any query?</p>
                                <h6>(+1 437 755 0525)</h6>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="w-100 m-0 mt-5">
                    <Col>
                        <ul className="for-link">
                            <h5>Company</h5>
                            <li onClick={() => navigate('/')}>Home</li>
                        </ul>
                    </Col>
                    <Col>
                        <ul className="for-link">
                            <h5>Usefull Links</h5>
                            <li>Careers</li>
                            <li>About Us</li>
                            <li>Privacy Policy</li>
                        </ul>
                    </Col>
                    <Col>
                        <ul className="for-link">
                            <h5>Contact</h5>
                            <li onClick={() => navigate('/contact-us')}>Contact Us</li>
                        </ul>
                    </Col>
                    <Col className="">
                        <p>
                            Email us at: <br /> wahajhaider@live.com
                        </p>
                        <div className="d-flex">
                            {socialIcons.map((item) => (
                                <div className={activeIcon === item.id ? 'active-social-icons' : 'social-icon'} onClick={(id) => setActiveIcon(item.id)}>
                                    <img src={item.icon} alt="name" width={item.width} />
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
            <hr style={{ color: 'white' }} />
            <div className="footer-end">
                <p className="m-0">©2023 Wimtech Solutions. All Rights Reserved</p>
            </div>
        </div>
    );
};

export default Footer;
