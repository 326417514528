import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import ApplicationRoutes from './Components/ApplicationRoutes/ApplicationRoutes';

function App() {
    return (
        <div className="App">
            <ApplicationRoutes />
        </div>
    );
}

export default App;
