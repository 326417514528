import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import './MvpDevelopment.scss';
import MvpServices from './MvpServices/MvpServices';
import BlockchainPortfolio from '../Blockchain/BlockchainPortfolio/BlockchainPortfolio';
import TechnologyStack from '../TechnologyStack/TechnologyStack';
import MvpProcess from './MvpProcess/MvpProcess';

const MvpDevelopment = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    });

    return (
        <div className="mt-5 mvp-page">
            <Container>
                <Row className="w-100 m-0 align-items-center">
                    <Col>
                        <h2>MVP Development Services</h2>
                        <p>
                            Empower your business with a Minimum Viable Product that delivers maximum value. Get a head start in your market with our MVP development solutions, delivering a lean and
                            efficient solution to meet your business requirements.
                        </p>

                        <button className="start-btn mb-3">Let's Get Started</button>
                    </Col>
                    <Col>
                        <ContactUsForm />
                    </Col>
                </Row>

                <div className="mt-5">
                    <MvpServices />
                </div>

                <div className="mt-5">
                    <MvpProcess />
                </div>

                {/* <div className="mt-5">
                    <BlockchainPortfolio />
                </div> */}

                <div className="mt-5">
                    <TechnologyStack />
                </div>
            </Container>
        </div>
    );
};

export default MvpDevelopment;
