import React, { useEffect } from 'react';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { Col, Container, Row } from 'react-bootstrap';
import TechnologyStack from '../TechnologyStack/TechnologyStack';
import MobileServices from './MobileServices/MobileServices';
import './MobileDevelopment.scss';
import MobileProcess from './MobileProcess/MobileProcess';
import MobilePortfolio from './MobilePortfolio/MobilePortfolio';

const MobileDevelopment = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    });

    const heading = 'Our Mobile App Development Services';
    const description =
        'We develop exceptional mobile experiences across iOS, Android, and wearable devices with our world-class mobile app development services. Our team of experts uses the latest technologies and methodologies to bring your app idea to life.';

    const processHeading = 'Our Mobile App Development Process';
    const processDescription = 'Each step of the blockchain development process is critical to the project’s success and must be executed carefully to ensure the desired outcomes are achieved.';

    const portfolioHeading = 'Mobile App Development Portfolio';
    const portfolioDescription = 'Unlock the potential of your online presence with Wim Tech proven track record of delivering top-notch web solutions to over 500 clients worldwide';

    return (
        <div className="mobile-page mt-5">
            <Container>
                <Row className="w-100 m-0 align-items-center">
                    <Col>
                        <h2>Mobile App Development Services</h2>
                        <p>
                            Empower your business with Wim Tech's custom mobile app development solutions. Focusing on cutting-edge technology and seamless user experience, we deliver mobile apps that
                            are functional and visually stunning.
                        </p>

                        <button className="start-btn mb-3">Let's Get Started</button>
                    </Col>
                    <Col>
                        <ContactUsForm />
                    </Col>
                </Row>

                <div className="mt-5">
                    <MobileServices heading={heading} description={description} />
                </div>

                <div className="mt-5">
                    <MobileProcess processHeading={processHeading} processDescription={processDescription} />
                </div>

                {/* <div className="mt-5">
                    <MobilePortfolio portfolioHeading={portfolioHeading} portfolioDescription={portfolioDescription} />
                </div> */}

                <div className="mt-5">
                    <TechnologyStack />
                </div>
            </Container>
        </div>
    );
};

export default MobileDevelopment;
