import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import crypto from '../../../assets/images/blockchain-portfolio/cryptocurrency.jpg';
import nft from '../../../assets/images/blockchain-portfolio/download.jpg';
import nfts from '../../../assets/images/blockchain-portfolio/image.jpg';
import posted from '../../../assets/images/blockchain-portfolio/pasted.png';
import './BlockchainPortfolio.scss';
import { useLocation } from 'react-router-dom';

const BlockchainPortfolio = () => {
    const location = useLocation();

    const portfolioData = [
        {
            id: 1,
            img: crypto,
            title: 'Project 1'
        },
        {
            id: 2,
            img: nfts,
            title: 'Project 2'
        },
        {
            id: 3,
            img: nft,
            title: 'Project 3'
        },
        {
            id: 4,
            img: posted,
            title: 'Project 4'
        }
    ];

    const changeHeading = () => {
        const path = location.pathname;

        if (path === '/web-development') {
            return <h2>Web Development Portfolio</h2>;
        } else if (path === '/blockchain') {
            return <h2>Blockchain Development Portfolio</h2>;
        } else if (path === '/ui-ux-designs') {
            return <h2>UI/UX Design Portfolio</h2>;
        } else if (path === '/mvp-development') {
            return <h2>Our MVP Development Process</h2>;
        } else {
            return <h2>Other Portfolio</h2>;
        }
    };

    return (
        <div className="portfolio-bc">
            {/* <Container>
                {changeHeading()}

                <p>Unleash the Power of Your Online Presence with Wim Tech</p>

                <Row className="w-100 m-0 mt-5 align-items-center">
                    {portfolioData.map((item) => (
                        <Col lg={6} className="mb-5">
                            <div className="section-img">
                                <div>
                                    <img src={item.img} alt={'img'} width="100%" />
                                </div>

                                <div className="show-hover">
                                    <h5>{item.title}</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                    <div className="hover-btn">
                                        <button>View Study Case</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
                <Row className="w-100 m-0 ">
                    <Col className="d-flex justify-content-center">
                        <button className="explore-btn">Explore More</button>
                    </Col>
                </Row>
            </Container> */}
        </div>
    );
};

export default BlockchainPortfolio;
