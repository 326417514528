import React, { useEffect } from 'react';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { Col, Container, Row } from 'react-bootstrap';
import TechnologyStack from '../TechnologyStack/TechnologyStack';
import MobileServices from '../MobileDevelopment/MobileServices/MobileServices';
import MobileProcess from '../MobileDevelopment/MobileProcess/MobileProcess';
import MobilePortfolio from '../MobileDevelopment/MobilePortfolio/MobilePortfolio';

const MachineLearningAndAI = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    });

    const heading = 'Our Machine learining and AI Services Services';
    const description =
        'These services are instrumental in automating tasks, predicting outcomes, and improving user experiences across various industries. They empower businesses and individuals to harness the power of data-driven insights and automation for innovation and efficiency.';

    const processHeading = 'Our Machine learining and AI Services Process';
    const processDescription =
        'These services are instrumental in automating tasks, predicting outcomes, and improving user experiences across various industries. They empower businesses and individuals to harness the power of data-driven insights and automation for innovation and efficiency.';

    const portfolioHeading = 'Machine learining and AI Portfolio';
    const portfolioDescription = 'Unlock the potential of your online presence with Wim Tech proven track record of delivering top-notch web solutions to over 500 clients worldwide';

    return (
        <div className="mobile-page mt-5">
            <Container>
                <Row className="w-100 m-0 align-items-center">
                    <Col>
                        <h2>Machine learining and AI Services</h2>
                        <p>
                            These services are instrumental in automating tasks, predicting outcomes, and improving user experiences across various industries. They empower businesses and individuals
                            to harness the power of data-driven insights and automation for innovation and efficiency.
                        </p>

                        <button className="start-btn mb-3">Let's Get Started</button>
                    </Col>
                    <Col>
                        <ContactUsForm />
                    </Col>
                </Row>

                <div className="mt-5">
                    <MobileServices heading={heading} description={description} />
                </div>

                <div className="mt-5">
                    <MobileProcess processHeading={processHeading} processDescription={processDescription} />
                </div>

                {/* <div className="mt-5">
                    <MobilePortfolio portfolioHeading={portfolioHeading} portfolioDescription={portfolioDescription} />
                </div> */}

                <div className="mt-5">
                    <TechnologyStack />
                </div>
            </Container>
        </div>
    );
};

export default MachineLearningAndAI;
