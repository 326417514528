import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import mail from '../../assets/images/mail.png';
import phone from '../../assets/images/phone.png';
import location from '../../assets/images/location.png';
import './ContactUs.scss';

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    });

    return (
        <div className="mt-5">
            <div className="contact-section">
                <div className=" mt-3 d-flex justify-content-center align-items-center flex-column">
                    <div>
                        <h2 className="text-white">Contact Us</h2>
                    </div>
                    <div>
                        <p className="touch-text">Get in touch with us</p>
                    </div>
                </div>
                <Row className="w-100 m-0  mt-5 mb-5">
                    <Col xl={6} lg={6} className="form-section">
                        <div className="for-width">
                            <div className="heading-test">
                                <h2>Connect with the Team</h2>
                            </div>
                            <br></br>
                           
                            <p>
                           <b> Welcome to WimTech Your Gateway to Innovative Software Solutions! </b>
                            <br></br>
                            <br></br>

At WimTech, we're passionate about transforming ideas into reality through cutting-edge software services. Whether you're envisioning a custom software solution, seamless web applications, or powerful mobile apps, we've got you covered!
<br></br>
<br></br>
<b>Why Choose WimTech?</b>
<br></br>
<br></br>
<b>Tailored Solutions:</b> We craft software solutions to fit your unique needs.
<br></br>
<b>Expert Team:</b> Our skilled professionals are dedicated to delivering excellence.
<br></br>
<b>Proven Results:</b> Experience tangible results that drive business success.
<br>
</br>
<br></br>
<b>Ready to embark on a digital journey with us?</b>
                            </p>

                            <div className=" for-contacts w-100 m-0 align-items-center mt-5">
                                <div className="phone">
                                    <img src={phone} alt="phone" />
                                </div>

                                <div className="ms-3">
                                    <p>Have any question?</p>
                                    <h6>(+1 437 755 0525)</h6>
                                </div>
                            </div>
                            <div className=" for-contacts w-100 m-0 align-items-center mt-5">
                                <div className="phone">
                                    <img src={mail} alt="phone" />
                                </div>

                                <div className="ms-3">
                                    <p>Write a email</p>
                                    <h6>wahajhaider@live.com</h6>
                                </div>
                            </div>
                            <div className=" for-contacts w-100 m-0 align-items-center mt-5">
                                <div className="phone">
                                    <img src={location} alt="phone" />
                                </div>

                                <div className="ms-3">
                                    <p>View our location</p>
                                    <h6>100 Leeward Glenway, Toronto Canada</h6>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col xl={6} lg={6} className="form-section mt-5 mt-lg-0 mt-xl-0">
                        <ContactUsForm />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ContactUs;
