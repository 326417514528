import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './MobileProcess.scss';

const MobileProcess = ({ processHeading, processDescription }) => {
    const proccessDsts = [
        {
            heading: 'Concept',
            description: 'Research the target audience, identify their needs and goals, and the main problems the UI/UX design needs to solve.'
        },
        {
            heading: 'Design',
            description: 'Develop wireframes, mockups, and prototypes to create a visual representation of the blockchain application’s UI/UX.'
        },
        {
            heading: 'Development',
            description: 'Develop the solution using relevant technology by taking the design specifications and turning them into an application.'
        },
        {
            heading: 'Planning',
            description: 'Create a roadmap for design and development by defining key concepts, objectives, and features.'
        },
        {
            heading: 'Testing',
            description: 'Optimize the product by testing it, gathering user feedback, and making improvements to enhance the user experience.'
        },
        {
            heading: 'Launch',
            description: 'Deploy the product, ensure user-friendliness, and keep the product up-to-date for long-term success.'
        }
    ];

    return (
        <div className="mobile-process">
            <Container>
                <Row className="w-100 m-0">
                    <Col>
                        <h2>{processHeading}</h2>
                        <p>{processDescription}</p>
                    </Col>
                </Row>

                <Row className="w-100 m-0 mt-3">
                    {proccessDsts.map((item) => (
                        <Col lg={6} className="mb-3">
                            <div className="for-content">
                                <h4>{item.heading}</h4>
                                <p>{item.description}</p>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};
export default MobileProcess;
