import React from 'react';
import website from '../../../assets/images/web-services-img/website.png';
import custom from '../../../assets/images/web-services-img/settings.png';
import content from '../../../assets/images/web-services-img/content-management.png';
import ecommerce from '../../../assets/images/web-services-img/ecommerce.png';
import support from '../../../assets/images/web-services-img/support.png';
import { Col, Row } from 'react-bootstrap';
import './MobileServices.scss';

const MobileServices = ({ description, heading }) => {
    const servicesData = [
        {
            img: website,
            title: 'eCommerce',
            description: 'Transform your online sales with our expertly developed eCommerce applications, delivering a seamless user experience.'
        },
        {
            img: custom,
            title: 'Marketing',
            description: 'Expand your marketing reach and connect with customers through our custom app development.'
        },
        {
            img: content,
            title: 'Finance',
            description: 'Streamline your transactions with our app development featuring the latest technology.'
        },
        {
            img: ecommerce,
            title: 'Business',
            description: 'For over a decade, client satisfaction has been our top priority. Partner with us for reliable solutions.'
        },
        {
            img: support,
            title: 'Social',
            description: 'Foster connection and seamless interactions with our custom social app development.'
        }
    ];
    return (
        <div className="mobile-service">
            <Row className="w-100 m-0">
                <Col lg={12}>
                    <h2>{heading}</h2>
                    <p>{description}</p>
                </Col>

                <Col lg={12}>
                    <Row className="w-100 m-0">
                        {servicesData.map((item) => (
                            <Col lg={4} xs={12} sm={6} md={6} xl={4} className="mb-3 ps-xl-0">
                                <div className="serives-content">
                                    <div className="for-img mb-3">
                                        <img src={item.img} alt={item.img} width="40px" />
                                    </div>
                                    <p className="mb-2">{item.title}</p>
                                    <span>{item.description}</span>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default MobileServices;
