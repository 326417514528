import React from 'react';
import website from '../../../assets/images/web-services-img/website.png';
import custom from '../../../assets/images/web-services-img/settings.png';
import content from '../../../assets/images/web-services-img/content-management.png';
import ecommerce from '../../../assets/images/web-services-img/ecommerce.png';
import support from '../../../assets/images/web-services-img/support.png';
import { Col, Row } from 'react-bootstrap';
import './MvpServices.scss';

const MvpServices = () => {
    const servicesData = [
        {
            img: website,
            title: 'DevOps & Cloud',
            description: 'Transform your software delivery with cutting-edge DevOps and cloud solutions. '
        },
        {
            img: custom,
            title: 'Online Marketing Services',
            description: 'Drive traffic, increase conversions & boost brand visibility with our customized online marketing services'
        },
        {
            img: content,
            title: 'Project Management Services',
            description: 'Streamline your project delivery and achieve success with expert project management services.'
        },
        {
            img: ecommerce,
            title: 'MVP Development',
            description: 'Bring Your Product Vision to Life with Our MVP Development Services – Innovative Solutions, Seamless Execution!'
        },
        {
            img: support,
            title: 'Support & Maintenance',
            description: 'Sustain Success with Seamless MVP Support – Let us handle the technicalities so you can focus on growth.'
        }
    ];

    return (
        <div className="mvp-service">
            <Row className="w-100 m-0">
                <Col lg={12}>
                    <h2>Our MVP Development Services</h2>
                    <p>
                        Craft a seamless user experience by incorporating early feedback, conducting usability tests, and prioritizing user-centric design. Ensure future growth by building a scalable
                        architecture and utilizing data-driven insights for informed decision-making. Embrace an agile approach, stay security-focused, and foster community engagement for a
                        well-rounded MVP development.
                    </p>
                </Col>

                <Col lg={12}>
                    <Row className="w-100 m-0">
                        {servicesData.map((item) => (
                            <Col lg={4} xs={12} sm={6} md={6} xl={4} className="mb-3 ps-xl-0">
                                <div className="serives-content">
                                    <div className="for-img mb-3">
                                        <img src={item.img} alt={item.img} width="40px" />
                                    </div>
                                    <p className="mb-2">{item.title}</p>
                                    <span>{item.description}</span>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default MvpServices;
